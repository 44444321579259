.carrier-risk-rep{
  color:#fff;

  
}
.carrier-head{
color: #06377b;
}

.mono-disorders{
  color:#fff;
}
.btn-click{
  background: #f99d1b;
  box-shadow: 0px 6.5625px 13.125px rgba(0, 0, 0, 0.33);
  border: 1px solid #d45e1b;
  border-radius: 4px;
  width: 225px;
  height: 35px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  margin: auto;
}
.unsorted-risk{
  font-size: 16px !important;
  line-height: 30px;
}
.carrier-img{
  width: 220px;
margin-left: 150px;  
}

.cardio-rep-box{
  margin-top: 20px;
}
.carrier-rep-box{
  display: flex;
  z-index: 1;
  height: 226px;
  width: 100%;
  background: linear-gradient(
    110.27deg,
    rgba(6, 55, 123, 0.8) 4.2%,
    rgba(16, 107, 147, 0.8) 27.5%,
    rgba(7, 138, 110, 0.8) 69.73%,
    rgba(55, 190, 128, 0.8) 97.39%
  );
  font-size: 10px;
  /* padding-top: 20px; */
  border-radius: 6px;
  /* margin-top: 50px; */
  color:#fff;
  /* padding-right:10px; */
}
.col-risk {
  /* margin-left: 50px; */
  text-align: end;
}

.carrier-para{
  padding-left: 10px;
}
.type-head{
font-weight:600;
font-size:28px;
line-height: 43.58px;
color: #fff;

}

.ar-disorder{
  height:200px;
  width: 300px;
  background-color: #fff;
  border-radius: 6px;
  align-content: center;
  margin-left: 40px;
  box-shadow: 1px 2px 13px 1px #44444430;
}

.ar-disorder > .ar-head{
 
}

.ar-disorder-2{
  height:200px;
  width: 300px;
  background-color: #fff;
  border-radius: 6px;
  align-content: center;
 margin-left: 80px;
 box-shadow: 1px 2px 13px 1px #44444430;

}
.table-risk{
  background-color: #078A6E;
  padding-top: 10px;
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 10px;
 
 
}

.table-risk table tr:first-child{
  color: #ffffff;
  background-color: #06377b;
  font-size: 10px;
}
.table-risk table {
  border-collapse: separate;
}

.table-risk table tr:not(:first-child){
  color: #ffffff;
  background-color: #c7c7c7;
}

.para-car{
  margin-left: 15px;
  margin-right: 5px;
}
.ar-head-2{
  margin-left: 39px;
}

.ar-head{
  margin-left: 72px;
}

.table-carrier{
  font-size: 16px;
  border-spacing:4px ;
  box-sizing: border-box;
  vertical-align: middle;
}

.table-carrier tbody tr:first-child{
  background-color: #fff;
}

.table-carrier tbody tr:not(:first-child){
  background-color:#D9D9D9 ;
}

.p10{
  padding: 5px;
}
.p11{
margin-top: 30px;
}

.p12{
  display: flex;
  justify-content: center;
}
.p13{
  margin-right: 250px;
}

.imageCarrier{
margin-right: 80px;
margin-top: 30px;
}
/* .meta-lists ul li::before {
  content: "\2022";
  color: #f99d1b;
  margin-left: -1rem;
  font-size: 10px;
} */
.carrierFamily{
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.p15{
  display: flex;
flex-direction: column;
justify-content: space-between;
width: 150px;
height: auto;
}

.p16{
font-size: 15px;
}

@media (max-width: 565px){
  .cancer-bread{
    display:flex;
    /*  */
  
    flex-direction: column;
  }
  .btn2{
    background: #f99d1b;
    border: 1px solid #f99d1b;
    
    border-radius: 30px;
    box-shadow: 0px 4px 7px 2px #ffdd9e69;
    color: #fff;
  }
  .btncol{
    width: auto;
    padding-bottom: 10px;
  }
  .bread-wid{
    width: auto;
  }

  .car-pa{
    padding-top: 15px;
  }
 }
