.pharma-report-container{
font-size: 16px;
}


.pharama-repo-tabl table  td:first-child{

  background-color: #0d5cda;
}

.pharama-repo-tabl table  td:first-child{
  background-color: #CCE9E0;
}

.drug-table-1 table thead td:nth-child(3){
  width: 800px !important;
}

.drug-table-1{
  width: 100%
}

.tabpharma{
  font-size: 13px;
}
.backrowX{
  background-color: #f8a5a5;
}
.backrowY{
  background-color: rgb(170, 221, 198);
}
.backrowN{
  background-color: #d6d8db;
}
.backrowW{
  background-color: rgb(240, 211, 177);
}
@media (max-width: 1200px){
  .report-buttons{

  }
  .schApp{
    font-size: 11px;
  }
  .schIcon{

  }
 }

 @media (max-width: 650px){
  
  .schApp{
    font-size: 9px !important;
  }
  
 }