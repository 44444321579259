.download-container {
  height: 100%;
  background: white;
  border-radius: 5px;
}

.download-table {
  height: 100%;
}
.download-table thead th {
  font-size: 16px;
  font-weight: 700;
  padding: 15px 10px;
}

.download-table tbody tr:not(:last-child) {
  height: 25px;
}
.download-table tbody td {
  font-size: 14px;
  font-weight: normal;
  border: none;
  padding: 15px 10px;
}

.download-container table tbody tr td:nth-child(odd) {
  background-color: rgba(217, 217, 217, 0.51);
}

.download-container .download-table .icon-report,
.download-container .download-table .icon-download {
  text-align: center;
}

.download-container .download-table .icon-report > img,
.download-container .download-table .icon-download > img {
  width: 18px;
}

.download-container .download-table .icon-report > img:hover,
.download-container .download-table .icon-download > img:hover {
  cursor: pointer;
}
