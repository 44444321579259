.hiHolder img{height:60px}
.quickReportBtn{
    width: auto;
    padding: 5px;
    font-size: 12px;
    background-color: #eda424;
    color: #fff;
}

.bxShdow{
    /* box-shadow: 0px 1px 15px 10px rgb(177 201 205 / 23%); */
}
.bdrNone{
    border:none;
}



.main-cards{
    width: 100%;
}

/* .cardHeight{
    height: 60vh;
} */




