.pharma-list{
  font-size: 16px;
}

.unsorted-pharam{
  font-size: 16px;
}

.genes{
  font-size: 16px;
  background-color: white;
}

.p-head{
  color: #06377B;
}

@media (max-width: 565px){
  .cancer-bread{
    display:flex;
    /*  */
  
    flex-direction: column;
  }
  .btn2{
    background: #f99d1b;
    border: 1px solid #f99d1b;
    
    border-radius: 30px;
    box-shadow: 0px 4px 7px 2px #ffdd9e69;
    color: #fff;
    
   

  }
  .btncol{
    width: auto;
    padding-bottom: 10px;
  }
  .bread-wid{
    width: auto;
  }
 }
