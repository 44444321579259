.faq-containers .faq-heading {
  font-size: 32px;
  font-weight: 400;
  color: white;
  border-bottom: 1px solid rgba(210, 210, 210, 0.79);
  padding-bottom: 12px;
}
.faq-containers .faq-accordian {
  width: 100%;
}

.faq-containers .faq-accordian .accordion-item {
  margin: 20px 0;
  border-radius: 5px;
}

.faq-containers .faq-accordian .accordion-item .accordion-button {
  border-radius: 5px;
}

.faq-containers .faq-accordian .accordion-item .accordion-button::after {
  background-image: url('https://github.com/nehasaipangallu/strand2/blob/main/public/plus.svg?raw=true');
}
