.img-box-meta {
  width: 500px;
  height: auto;
}

img.meta-img {
  text-align: center !important;
  width: 236px !important;
  height: 124px;
  margin-top: 48px;
}

.aligned-p{
  margin-bottom: 0px;
}
.meta-4{
  width: auto;
  height:300px;
}
.meta-head {
  color: #000;
}
.meta-para {
  color: #333;
}
.cardiac-condition {
  height: auto;
  width: 400px;
  border-top: 25px;
  padding: 40px;
}

.exam-para {
  color: #fff;
}

.meta-list ul {
  list-style: none;
}
.meta-list ul li {
  margin: 0.6rem;
}
.meta-list ul li::before {
  content: '\2022';
  color: #f99d1b;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.meata-sec-p {
  background-color: white;
  box-shadow: 1px 2px 13px 1px #44444430;
  border-radius: 10px;
}

hr {
  color: #000;
  width: 100%;
}

.report-container {
  background: linear-gradient(
    rgb(82 131 199 / 80%) 4.2%,
    rgba(16, 107, 147, 0.8) 27.5%,
    rgba(7, 138, 110, 0.8) 69.73%,
    rgba(55, 190, 128, 0.8) 97.39%
  );
  height: 226px;
  width: 100%;
  padding: 14px !important;
  border-radius: 6px;
}

.report-container .para-1 {
  color: white;
  /* line-height: 28px; */
  margin-top: 25px;
  text-align: justify;
}

.para-2 {
  color: white;
  /* line-height: 28px; */
  margin-top: 23px;
  text-align: justify;
}



.cardic-condition-box {
  box-shadow: 1px 2px 13px 1px #44444430;
}

.iem-disorder-table {
  border-collapse: separate;
  font-size: 14px;
  border-spacing: 0 5px;
}


@media (max-width: 565px){
  .cancer-bread{
    display:flex;
    /*  */
  
    flex-direction: column;
  }
  .btn2{
    background: #f99d1b;
    border: 1px solid #f99d1b;
    
    border-radius: 30px;
    box-shadow: 0px 4px 7px 2px #ffdd9e69;
    color: #fff;
    
   

  }
  .btncol{
    width: auto;
  }
  .bread-wid{
    width: auto;
  }
 }
