/* Global prop */
.sidebarWrap{
  background-color: #313541;
}
.sideBar {
  background-color: #313541;
  min-width: 100%;
}

.sidebarHero {
  background-color: #e14d45;
  font-size: 12px;
  padding: 15px 10px;
  color: #ffffff;
  width: 100%;
  text-align: center;
}

.sideBarNav ul {
  margin: 0;
  padding: 0;
  
}

.sideBarNav ul li {
  margin: 0;
  list-style-type: none;
  padding: 20px 10px;
  /* margin-top: -6px; */
  border-bottom: 1px solid #404040;
}
.adult-icon{
  width:"30px"
}

.sideBarNav ul li a {
   
  text-decoration: none;
  font-size: 16px;
  color: rgb(36, 36, 36);
  cursor: pointer;
  overflow-wrap: break-word;
}
.sideBarNav .navIcon {
  text-align: center;
}
.sideBarNav .navIcon img {
  height: 50px;
}



.sideBarNav .navLabel {
  text-align: center;
  font-weight: 500;
  color: #5b5f66;
  padding: 10px 0 10px 0;
}

.sideBarNav .navLabel.close {
  text-align: center;
  font-weight: 800;
  color: rgb(36, 36, 36);
  padding: 6px 0 6px 0;
  background-color: #f1b827;
    border-radius: 30px;
    color: #000!important;
}

.zoom {


  transition: transform .2s; /* Animation */
 

 }
 .zoom{
 
 }


 .blank-img1 {  
  
  transition: all 0.2s ease-in-out;
 }
 .blank-img1:hover {
  
  background-color: #EDA423 !important;
  transition: all 0.4s ease-in-out;
 }

.slideSubMenu {
  position: absolute;
  top: 0px;
  left: 100%;
  width: 125px;
  z-index: 999;
  background-color: #000000;
  padding: 20px;
  border: 1px solid #000000;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: all 0.4s ease-in-out;
}

@media screen and (max-width:1800px) {
  .slideSubMenu{
    position: absolute;
  top: 0px;
  left: 150px;
  width: 125px;
  z-index: 999;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #eaeaea;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  }
}

.subMenu ul li {
  margin: 0;
  list-style-type: none;
  padding: 10px;
  border-bottom: 1px solid #404040;
}

.subMenu.navIcon img {
  height: 30px;
}

.subMenu ul li a {
  display: block;
  text-decoration: none;
  font-size: 13px;
  color: rgb(36, 36, 36);
  cursor: pointer;
}


.sideSubHover:hover{
  background: #FAF9F9;
}




.health-page {
  width: 100%;
  position: relative;
  z-index: 1;
  height: calc(100vh - 120px);
  background: linear-gradient(
    110.27deg,
    rgba(6, 55, 123, 0.8) 4.2%,
    rgba(16, 107, 147, 0.8) 27.5%,
    rgba(7, 138, 110, 0.8) 69.73%,
    rgba(55, 190, 128, 0.8) 97.39%
  ) no-repeat;
  color: white;
}

.health-page::after {
  content: '';
  background-image: url('https://github.com/nehasaipangallu/strand2/blob/main/public/20043%201.svg?raw=true');
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  z-index: 0;
}

.flex-cont > .img-cap {
  display: flex;
  background-color: white;
  z-index: 1;
  height: 146.5px;
  width: 200px;
  margin: 1px;
  text-align: center;
  font-size: 10px;
}

.flex-cont > .health-in {
  display: flex;
  background-color: rgb(255, 255, 255);
  z-index: 1;
  width: 200px;
  font-size: 15px;
}

.pharmahead{
  font-size: 8px;
}
.hover1:hover{
background-color:#EDA423 !important;
}

.next-nav-1 {
  display: flex;
  width: 100px;
  height: 100px;
  position: absolute;
  background-color: rgb(255, 255, 255);
  left: 105%;
  top: 0;
}

.img-cap > .un-list {
  position: relative;
  height: 100%;
  width: 100%;
}
.next-nav-2 {
  display: flex;
  width: 100px;
  height: 100px;
  background-color: rgb(255, 255, 255);
  left: 105%;
  top: 101px;
  position: absolute;
}

.next-nav-3 {
  display: flex;
  width: 100px;
  height: 100px;
  background-color: rgb(255, 255, 255);
  left: 105%;
  top: 202px;
  position: absolute;
}

.navLabel {
  font-size: 13px;
  color: #ffffff !important;
}


@media screen and (max-width:1900px) {
    .slideSubMenu{
      position: absolute;
      top: 0px;
      left: 100%;
      width: 100px;
      z-index: 999;
      background-color: #000000;
      padding: 18px;
      border: 1px solid #000000;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
}
/* @media screen and (max-width:1791px){
  .slideSubMenu{
    position: absolute;
    top: 0px;
    left: 100%;
    width: 125px;
    z-index: 999;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #eaeaea;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
} */

@media (max-width:995px) {
  .sidebarWrap{
  visibility: hidden !important;
 
  }
}


