.side-panel {
  height: Calc(100% - 90px);
  width: 320px;
  position: fixed;
  right: 0;
  top: 90px;
  background-color: #f5f4f4;
}

.side-panel .close-image:hover {
  cursor: pointer;
}

.side-panel * {
}

.side-panel .side-panel-heading {
  font-weight: 700;
  color: #7a7a7a;
}

.side-panel .heading {
  color: #b60a02;
  font-weight: 600;
  font-size: 22px;
}

.side-panel .heading-2 {
  color: #b1b1b1;
  font-weight: 400;
  font-size: 22px;
}

.side-panel p {
  color: #898989;
  font-size: 13px;
}

.side-panel .search-container {
  position: relative;
}
.side-panel .search-container::after {
  content: '';
  position: absolute;
  top: 20px;
  background-image: url('https://github.com/nehasaipangallu/strand2/blob/main/public/search-gray.svg?raw=true');
  width: 30px;
  height: 30px;
  right: 10px;

  background-repeat: no-repeat;
}
.side-panel .search-container .search-text {
  height: 33px;
  width: 245px;
  border-radius: 2px;
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  outline: none;
  font-size: 14px;
  color: #898989;
}

.search-text::after {
  background-image: url(https://github.com/nehasaipangallu/strand2/blob/main/public/search.svg?raw=true);
  content: '';
}
