@media only screen and (max-width: 600px) {
    .css-1f562lt {
        position: absolute;
        top: 50%!important;
        left: 54%!important;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 300px;
        height: 100%;
        background-color: white;
        padding: 32px;
        border: none;
        box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);
    }
}

@media only screen and (max-width: 702px) {
    .css-1f562lt {
        position: absolute;
        top: 50%!important;
        left: 54%!important;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 300px;
        height: 100%;
        background-color: white;
        padding: 32px;
        border: none;
        box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);
    }
}



