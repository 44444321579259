.thank{
    height: 400px;
}
.btn-home{
    background: #f99d1b;
    border: 1px solid #f99d1b;
    border-radius: 30px;
    box-shadow: 0px 4px 7px 2px #ffdd9e69;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    height: 35px; 
   
    max-width: fit-content;   
    transition: all 0.5s ease-in-out;
    padding: 5px 25px;
}

.btn-home2{
    background: #ffffff;
  border: 1px solid #b5bfcf;
  border-radius: 30px;
  box-shadow: 0px 4px 7px 2px #cdc8ff69;
  color: rgb(80, 80, 80); 
  font-size: 14px;
  font-size: 14px;
  font-weight: 400;
  height: 35px; 
 
  max-width: fit-content;   
  transition: all 0.5s ease-in-out;
  padding: 5px 25px;
}
