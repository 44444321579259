.report-cardio{
  display: flex;
  z-index: 1;
  height: 226px;
  width: 100%;
  background: linear-gradient(
    rgb(82 131 199 / 80%) 4.2%, rgba(16, 107, 147, 0.8) 27.5%, rgba(7, 138, 110, 0.8) 69.73%, rgba(55, 190, 128, 0.8) 97.39%
  );
  font-size: 10px;
  margin-top: 30px;
  border-radius: 6px;
}
.cardio-class{
  color:#fff
}
.cardio-class-head{
   color: #06377B;  
    font-weight: 600;    
   
}
.cardio-bg{
  box-shadow: 1px 2px 13px 1px #44444430;
  border-radius: 20px;
  background-color: white!important;
  padding: 24px;
  margin: 20px 0 20px 0;
}
.tumor{
  color: #06377B;
  align-content: center;
  padding-left: 110px;
}
.unsorted-cardio{
  line-height: 30px;
  margin-top: 3px;
  font-size: 16px;
}
.cardio-box{
  width:100%;
  height: 500px;
  background-color: #fff;
}

.cardic-condition-box {
  display: flex;
  height:  537px;
  width: 1105.94px;;
  background-color: #fff;
  border-radius: 10px;
  font-size: 16px;
  align-content: space-between;
}

.cardio-img{
  width: 150px;
  height: 150px;
  top:10px;
}

.cardiac-condition{
  height: 500px;
  width: 400px;
}

.tumor-img{
  width: 500px;
  height:500px;
  left:0;
}
.arhy-img{
  width: 400px;
  height: 500px;
}
.heart-img{
  width: 400px;
  height: 500px;
}
.heart-2{
  width: 400px;
  height: 500px;
}




.cardio-1 h1{
  padding: 23px;
}
.carousel-cardio{
  border-radius: 40px;
    overflow: hidden;
    margin-bottom: 10px;
}

.carousel-cardio-2{
  border-radius: 40px;
  overflow: hidden;
}

.flex-box{
  height: auto;
  background-color: #fff;
  border-radius: 10px;
}

.flex-col{
  margin-top: 90px;
}

.img-fluid-col{
  width:250px;
  margin-left: 140px;
}

/* .carousel-item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 800px; 
} */

@media (max-width: 565px){
  .cancer-bread{
    display:flex;
    /*  */
  
    flex-direction: column;
  }
  .btn2{
    background: #f99d1b;
    border: 1px solid #f99d1b;
    
    border-radius: 30px;
    box-shadow: 0px 4px 7px 2px #ffdd9e69;
    color: #fff;
    
   

  }
  .btncol{
    width: auto;
    padding-bottom: 10px;
  }
  .bread-wid{
    width: auto;
  }
 }
