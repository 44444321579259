.adult-onset {
}

.adult-onset .adult-img-container {
  background-image: url('https://github.com/nehasaipangallu/strand2/blob/main/public/Rectangle%205200.svg?raw=true');
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
  background-size: cover;
}

.adult-route-container .route-row-1,
.adult-route-container .route-row-2 {
  width: 60%;
  margin: 0;
}
.adult-route-container .route-row-1 .row-1-heading {
  width: 75%;
  margin: 0;
}

.adult-route-container .route-row-1 .row-1-card {
  width: 150px;
  height: 215px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.adult-route-container .route-row-1 .row-1-card .btn2 {
  background: #f99d1b;
  box-shadow: 0px 6.5625px 13.125px rgb(0 0 0 / 33%);
  border: 1px solid #d45e1b;
  border-radius: 4px;
  width: 110px;
  height: 35px;
  color: white;
  font-size: 14px;
  font-weight: 400;
}

.adult-route-container .route-row-2 .row-2-card {
  width: 225px;
  height: 210px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.adult-route-container .route-row-2 .row-2-heading {
  margin: 0;
}
