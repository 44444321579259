/* @import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,600&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&display=swap"); */

body {
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
}

/* css */
html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div {
  font-family: "Helvetica", sans-serif;
}

.pageWrapper {
  background: linear-gradient(110.27deg,
      rgba(247, 247, 247, 0.8) 4.2%,
      rgba(236, 236, 236, 0.8) 27.5%,
      #e9e9e9 69.73%,
      rgba(224, 224, 224, 0.8) 97.39%);
}

.pageWrapper2 {
  background-color: rgb(217, 214, 212);
}

.innerWrapper {
  padding: 10px 25px;
}

.innerWrapper-article {
  padding: 10px 100px;
}

.breadcrumb li,
.breadcrumb li a {
  font-size: 12px;
  background-color: #dfe8eb;
  margin: 0 4px;
  border-radius: 30px;
  color: #5b5f66;
  font-weight: 500;
  text-decoration: none;
}

.breadcrumb li {
  font-size: 12px;
  background-color: #dfe8eb;
  margin: 0 4px;
  border-radius: 30px;
  color: #5b5f66;
  padding: 2px 20px;
  font-weight: 500;
  text-decoration: none;
}

.breadcrumb li a {
  font-size: 12px;
  background-color: #dfe8eb;
  margin: 0 0px;
  border-radius: 30px;
  padding: 2px 2px;
  color: #5b5f66;
  font-weight: 500;
  text-decoration: none;
}

.blueBG {
  background: linear-gradient(
      110.27deg,
      rgb(50 94 157) 4.2%,
      rgb(51 87 139) 27.5%,
      #33578b 69.73%,
      rgb(32 73 137) 97.39%
    ),
    #3866a7;
  color: #fff;
}


.greyBG {
  background-color: #ffffff;
}

.rowHoverReview:hover {
  background-color: #e6e8e6;
}

.header .nav-items {
  font-size: 16px !important;
}

/* COLORS */
.c1 {
  color: #fff !important;
}

.c2 {
  color: rgb(17, 17, 17) !important;
}

.c3 {
  color: #1ab586 !important;
}

.c4 {
  color: #6e8ea3 !important;
}

.c5 {
  color: #e04d47 !important;
}

.c6 {
  color: rgb(51, 74, 107) !important;
}

/* BG */
.bg1 {
  background-color: #fff !important;
}

/* FW */

.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

/* FONTSIZES */

/* .fs10 {
  font-size: 10px;
}
.fs12 {
  font-size: 12px;
}
.fs14 {
  font-size: 14px;
}
.fs16 {
  font-size: 16px;
}

.fs17 {
  font-size: 17px;
}
.fs18 {
  font-size: 18px;
}
.fs20 {
  font-size: 20px;
}
.fs22 {
  font-size: 22px;
}

.fs24 {
  font-size: 24px;
}

.fs26 {
  font-size: 26px;
}

.fs30 {
  font-size: 30px;
}
.fs34 {
  font-size: 34px;
}

.fs38 {
  font-size: 38px;
}

.fs45 {
  font-size: 45px;
} */

/* tables */
.table-health-1 table tbody tr td:nth-child(2) {
  word-break: break-all;
}

.marginCard {
  margin-top: 10px;
}

/* lineheights */

/* .lh22 {
  line-height: 22px;
}

.lh28 {
  line-height: 28px;
} */

.cursorPointer {
  cursor: pointer;
}

/* .pharmaImagenew{
  padding-left: 100px;
} */
.pharmacoGenomicsAlignment {
  margin-top: -60px;
}

/* HEADERS */
.italicGene {
  font-style: italic;
  margin-left: 10px;
  letter-spacing: 1px;
  text-align: start;
}

.pageWrapper {
  height: calc(100vh - 90px);
  overflow: auto;
}

.btn1 {
  background: #f99d1b;
  background: rgb(254, 174, 44);
  background: linear-gradient(90deg,
      rgba(254, 174, 44, 1) 0%,
      rgba(255, 192, 28, 1) 35%,
      rgb(233, 204, 41) 100%);
  box-shadow: 0px 3px 12px 5px rgb(31 118 78 / 33%);
  border-radius: 6px;
  width: auto;
  height: 55px;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
}

.btn1:hover {
  background: #fc9c15;
  border: 1px solid #fc9c15;
  padding-right: 25px;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.btn2 {
  background: #f99d1b;
  border: 1px solid #f99d1b;
  border-radius: 30px;
  box-shadow: 0px 4px 7px 2px #ffdd9e69;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  min-width: 200px;
  max-width: fit-content;
  transition: all 0.5s ease-in-out;
  padding: 5px 25px;
}

.btn3 {
  background: #ffffff;
  border: 1px solid #5a5a5a;
  border-radius: 30px;
  box-shadow: 0px 4px 7px 2px #ffdd9e69;
  color: rgb(80, 80, 80);
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  min-width: 200px;
  max-width: fit-content;
  transition: all 0.5s ease-in-out;
  padding: 5px 25px;
}

.btn3:hover {
  background: #ffffff;
  border: 1px solid #5a5a5a;
  border-radius: 30px;
  box-shadow: 0px 4px 7px 2px #ffdd9e69;
  color: rgb(80, 80, 80);
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  min-width: 200px;
  max-width: fit-content;

  transition: all 0.5s ease-in-out;
  padding: 5px 25px;
}

.btn4 {
  background: #ffffff;
  border: 1px solid #b5bfcf;
  border-radius: 30px;
  box-shadow: 0px 4px 7px 2px #cdc8ff69;
  color: rgb(80, 80, 80);
  font-size: 12px;
  font-weight: 400;
  min-width: fit-content;
  max-width: fit-content;
  transition: all 0.5s ease-in-out;
  padding: 5px 15px;
  text-decoration: none;
}

.btn4 a {
  text-decoration: none;
}

.btn4:hover {
  background: #ffffff;
  border: 1px solid #b5bfcf;
  border-radius: 30px;
  box-shadow: 0px 4px 7px 2px #8183ff69;
  color: rgb(80, 80, 80);
  font-size: 12px;
  font-weight: 400;
  min-width: fit-content;
  max-width: fit-content;

  transition: all 0.5s ease-in-out;
  padding: 5px 15px;
}

.btn5 {
  background: #ffffff;
  border: 1px solid #b5bfcf;
  border-radius: 30px;
  color: rgb(80, 80, 80);
  font-size: 16px;
  font-weight: 400;
  min-width: fit-content;
  max-width: fit-content;
  padding: 5px 20px;
  text-decoration: none;
  width: max-content;
}

.btn5 a {
  text-decoration: none;
}

.btn5:hover {
  background: #ffffff;
  border: 1px solid #797d84;
  border-radius: 30px;
  color: rgb(80, 80, 80);
}

.btn6 {
  background: #f5f5f5;
  border: none;
  color: #5f5f5f;
  font-size: 16px;
  font-weight: 600;
  min-width: fit-content;
  max-width: fit-content;
  padding: 5px 20px;
  text-decoration: none;
}

.btn6 a {
  text-decoration: none;
}

.btn6:hover,
.btn6:focus {
  background: #e5e5e5 !important;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 600;
  min-width: fit-content;
  max-width: fit-content;
}

.btn2:hover {
  background: #fc9c15;
  border: 1px solid #fc9c15;
  padding-right: 20px;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.btn7 {
  background: #d7e0f7;
  border: 1px solid #f2f2f2;
  border-radius: 30px;
  color: #5e5e5e;
  font-size: 16px;
  font-weight: 400;
  min-width: fit-content;
  max-width: fit-content;
  padding: 5px 20px;
  text-decoration: none;
  width: max-content;
}

.btn7:hover {
  background: #314b8d;
  border: 1px solid #eaeaea;
  border-radius: 30px;
  color: #fefefe;
  min-width: fit-content;
  max-width: fit-content;
  width: max-content;
}

.btn7:disabled {
  background: #cccccc;
  color: #eeeeee;
}

/* TOOLTIP */

.popToolTip {
  padding: 20px;
  border-radius: 10px;
  background-color: #fff !important;
}

.readyBadge {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #1ab586;
  border-radius: 50%;
  text-align: center;
  right: -20px;
  top: -20px;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 11px;
}

.cardTransparent {
  background-color: #ffffff;
}

/* height for images */
.height350 {
  height: 350px;
  width: auto;
}

.height100 {
  height: 100px;
  width: auto;
  object-fit: cover;
}

/* .height100 img{
  width:100%;
  height:100%;
} */
.objectPositionTop {
  object-position: top;
}

.goBack {
  cursor: pointer;
  padding: 0px 20px;
}

.widgetOne {
  padding: 20px;
  box-shadow: 0px 1px 15px 10px rgb(177 201 205 / 23%);
  background-color: #fff;
  border-radius: 12px;
}

.bdr-rad16 {
  border-radius: 16px;
}

.bdrNone {
  border: none !important;
}

.schApp {
  background-color: #e14d45;
  font-size: 22px;
  padding: 15px 10px;
  color: #ffffff;
  width: 100%;
  margin: 34px 0;
  border-radius: 30px;
  border: none;
}

.schIcon {
  position: absolute;
  top: -15px;
  background-color: #fff;
  width: 91px;
  height: 91px;
  border-radius: 50%;
  /* line-height: 91px; */
  padding: 10px;
  border: 4px solid #e14d45;
  left: 0;
}

.footerMobileNav {
  display: none;
}

.carousel-inner {
  position: relative;
  width: 96%;
  overflow: hidden;
  margin: auto;
}

.carousel-control-prev {
  left: 30px;
}

.carousel-control-next {
  right: 30px;
}

@media screen and (max-width: 992px) {

  .breadcrumb li,
  .breadcrumb li a {
    font-size: 12px;
    background-color: #dfe8eb;
    margin: 0 1px;
    border-radius: 30px;
    padding: 2px 4px;
    color: #444;
    font-weight: 500;
    text-decoration: none;
  }

  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: auto;
  }

  .img-1 {
    width: 200px !important;
  }

  .sideBar {
    display: none !important;
  }

  /* .footerMobileNav{
    display: block;
    position: fixed;
    bottom: 0;
    z-index: 9999;
    width: 100%;
    box-shadow: 1px 2px 13px 1px #44444430;
  }

  .footerMobileNav ul li{
    list-style-type: none; 
    
  }

  .footerMobileNav ul li img{
    height:30px
  }

  .footerMobileNav ul li a{
    text-decoration: none;
    color:#444
  }


  .footerMobileNav ul{
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:0;
    padding: 0;
    background-color: #fff;
    padding: 8px 10px;
    border-top: 1px solid #eaeaea;
  } */
}

.variantHighlightRed {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 600;
  background-color: #e14d45;
  text-align: center;
  color: #fff;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 8px;
  animation: pulse-animation-red 2s infinite;
}

.variantHighlightRed2 {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  font-weight: 600;
  background-color: #e14d45;
  text-align: center;
  color: #fff;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 8px;
  animation: pulse-animation-red 2s infinite;
}

.variantHighlightYellow {
  animation: pulse-animation 2s infinite;
}

.variantHighlightGreen {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 600;
  background-color: #1ab586;
  text-align: center;
  color: #fff;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 8px;
  animation: pulse-animation-green 2s infinite;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

.highlightHolder {
  background-color: #fafeff;
  padding: 10px 0;
  border-radius: 12px;
  /* padding-left: 30px; */
}

@keyframes pulse-animation-red {
  0% {
    box-shadow: 0 0 0 0px rgba(245, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(255, 2, 2, 0);
  }
}

@keyframes pulse-animation-green {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 119, 73, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(11, 150, 64, 0);
  }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(241, 206, 2, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(247, 230, 1, 0);
  }
}

.headCell1 {
  background-color: #4070b3 !important;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  border: 1px solid #fff;
  font-weight: 600;
}

.headCell2 {
  background-color: #4ab586 !important;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  border: 1px solid #fff;
  font-weight: 600;
}

.headCell3 {
  background-color: #ebbd28 !important;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  border: 1px solid #fff;
  font-weight: 600;
}

.headCell4 {
  background-color: #df4444 !important;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  border: 1px solid #fff;
  font-weight: 600;
}

.headCell5 {
  background-color: #6c757d !important;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  border: 1px solid #fff;
  font-weight: 600;
}

.textEnd {
  text-align: end !important;
}

.cellBg1 {
  background-color: #dcefe6 !important;
  border: 1px solid #fff;
}

.cellBg2 {
  background-color: #c1edd9 !important;
  border: 1px solid #fff;
}

.cellBg3 {
  background-color: #fbebb7 !important;
  border: 1px solid #fff;
}

.cellBg4 {
  background-color: #f8a5a5 !important;
  border: 1px solid #fff;
}

.cellBg5 {
  background-color: #d6d8db !important;
  border: 1px solid #fff;
}

/* mobile resnponsiveness */
@media (max-width: 565px) {

  .modalDesign {
    display: none !important;
  }

  /* .pageWrapper {
  height: auto;
  overflow: auto;
} */

  .table-health-1 table tbody tr td:nth-child(2) {
    word-break: normal !important;
  }

  .mobileFit span {
    position: absolute;
    left: 15px;
  }

  .mobileFit h1 {
    padding-left: 30px !important;
  }

  /* h2{
  padding-left: 30px;
 } */
}

.ques-background {
  width: auto;
  height: auto;
  background-color: white;
  border-radius: 1%;
}

.mobileAlignment {
  padding-top: 75px;
}

/* 
.row-color{
  background-color: rgb(233, 233, 233);
} */

.row-head {
  background-color: #c3c4c3;
}

/* .row-color2{
  background-color: rgb(255, 255, 255);
} */

/* cancer Report */
.cancerDesc {
  padding: "0px 12px 0px 12px";
}

.text-align {
  margin-left: 25px;
}

/* Align-buttons-bottom */

.align-buttons-bottom {
  display: flex;
  justify-content: space-between;
}

/* Dashboard radial style */

.HCRR {
  text-align: left;
  cursor: pointer;
  margin: 30px 0;
  padding: 20px;
  padding-left: 30px;
  background-color: #5782bf;
  color: #fff;
  font-size: 12px;
  border-radius: 12px;
  transition: 0.2s all ease;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  border: 6px solid #ffffff4f;
  margin-left: 60px;
}

.HCRR:hover {
  text-align: left;
  cursor: pointer;
  margin: 30px 0;
  padding: 20px;
  padding-left: 30px;
  background-color: #1c6fe2;
  color: #fff;
  font-size: 12px;
  border-radius: 12px;
  transition: 0.2s all ease;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  border: 6px solid #ffffff4f;
  margin-left: 60px;
}

.HCR {
  margin: 30px 0;
  text-align: left;
  cursor: pointer;
  padding: 20px;
  padding-left: 30px;
  background-color: #62c39d;
  color: #fff;
  font-size: 12px;
  border-radius: 12px;
  transition: 0.2s all ease;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  border: 6px solid #ffffff4f;
}

.HCR:hover {
  margin: 30px 0;
  text-align: left;
  cursor: pointer;
  padding: 20px;
  padding-left: 30px;
  background-color: #47da9f;
  color: #fff;
  font-size: 12px;
  border-radius: 12px;
  transition: 0.2s all ease;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  border: 6px solid #ffffff4f;
}

.Inborn {
  margin: 30px 0;
  padding: 20px;
  padding-left: 30px;
  text-align: left;
  cursor: pointer;
  background-color: #188484;
  color: #fff;
  font-size: 12px;
  border-radius: 12px;
  transition: 0.2s all ease;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  border: 6px solid #ffffff4f;
  margin-left: 60px;
}

.Inborn:hover {
  margin: 30px 0;
  padding: 20px;
  padding-left: 30px;
  text-align: left;
  cursor: pointer;
  background-color: #38afaf;
  color: #fff;
  font-size: 12px;
  border-radius: 12px;
  transition: 0.2s all ease;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  border: 6px solid #ffffff4f;
  margin-left: 60px;
}

.CaRisk {
  margin: 30px 0;
  text-align: left;
  cursor: pointer;
  background-color: #6585ed;
  color: #fff;
  font-size: 12px;
  padding: 20px;
  border-radius: 12px;
  transition: 0.2s all ease;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  border: 6px solid #ffffff4f;
}

.CaRisk:hover {
  margin: 30px 0;
  text-align: left;
  cursor: pointer;
  background-color: #3051c0;
  color: #fff;
  font-size: 12px;
  padding: 20px;
  border-radius: 12px;
  transition: 0.2s all ease;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  border: 6px solid #ffffff4f;
}

.PharGen {
  margin: 30px 0;
  text-align: left;
  cursor: pointer;
  background-color: #61a461;
  color: #fff;
  font-size: 12px;
  padding: 20px;
  border-radius: 12px;
  transition: 0.2s all ease;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  border: 6px solid #ffffff4f;
  justify-content: space-around;
}

.PharGen:hover {
  margin: 30px 0;
  text-align: left;
  cursor: pointer;
  background-color: #2c9b2c;
  color: #fff;
  font-size: 14px;
  padding: 20px;
  border-radius: 12px;
  transition: 0.2s all ease;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  border: 6px solid #ffffff4f;
  justify-content: space-around;
}

.heading-left {
  margin-left: auto;
}

/* carrierrisk image */
.carrierImage {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PharmaImageHero {
  margin-top: 60px;
  margin-left: 180px;
}

.dasboardImagePadding {
  padding-bottom: 0px;
}

.geneSymbol {
  font-style: 'italic';
  text-align: 'start';
  display: 'flex';
  flex-wrap: 'wrap';
}

@media (max-width: 576px) {
  .dasboardImagePadding {
    padding-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .PharmaImageHero {
    margin-top: -70px;
    margin-left: 0px;
    margin-bottom: 20px;
    padding: 20px 20px;
  }
}

@media (max-width: 1025px) {
  .heading-left {
    margin-left: -146px;
  }
}

@media (max-width: 1205px) {
  .cardHeight {
    height: 38vh;
  }
}

@media (max-width: 600px) {
  .extra-column {
    display: none;
  }
}

@media (max-width: 1800px) {
  .cardHeight {
    height: 100%;
  }
}

@media (max-width: 760px) {
  .adult-onset {
    display: block;
  }

  .aditional-repo {
    display: block;
  }
}

@media (max-width: 650px) {
  .breadForMobile {
    display: block;
  }

  .marginTop {
    margin-top: 10px !important;
  }
}

/* @media (max-width:2000px) {
  .carrierImage{
    padding-top:84px;
  }
} */

/* @media(max-width:770px){
  .carrierImage{
    padding-top:0;
  }
} */

.modalDesign {
  display: none;
}

.iCardCenter {
  text-align: center !important;
}

/* @media (max-width:992px) {
  .sideBar{
    display: none !important;
  }
} */

/* @media (max-width:700px) {
.mobileFit span{
  position: absolute;
  left:15px;
}
.mobileFit h1{
  left:18px !important;
}
} */
/* @media (max-width:1155px) {
  .mobileFit span{
    position: absolute !important;
    left: 10px !important;
  }
} */

/* @media screen and (min-device-width: 730px) and (max-device-width: 767px) {
 .carrierImage{
  margin-left: 41px;
 }
} */

@media screen and (min-device-width: 1800px) and (max-device-width: 9999px) {
  .cardHeight {
    height: 60vh;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1280px) {
  .metaCardPadding {
    padding-top: 10px !important;
  }

  .btn7 {
    padding: 5px 10px;
    width: max-content;
  }

  .btn7:hover {
    padding: 5px 10px;
    width: max-content;
  }

  .btn5 {
    padding: 5px 10px;
    width: max-content;
  }

  .btn5:hover {
    padding: 5px 10px;
    width: max-content;
  }
}

/* @media screen and (min-device-width: 680px) and (max-device-width: 767px){
 .PharmaImageHero{
  margin-left: 66px !important;
 }
 } */

@media (max-width: 768px) {
  .metabolism-carousel {
    margin-top: 0px;
  }

  .btn7 {
    padding: 5px 10px;
    width: max-content;
    min-width: 100%;
  }

  .btn7:hover {
    padding: 5px 10px;
    width: max-content;
    min-width: 100%;
  }

  .btn5 {
    padding: 5px 10px;
    width: max-content;
    min-width: 100%;
  }

  .btn5:hover {
    padding: 5px 10px;
    width: max-content;
    min-width: 100%;
  }
}

.cardPadding {
  padding-bottom: 0px;
}

@media (max-width: 576px) {
  .cardPadding {
    padding-bottom: 10px;
  }
}

.dropbtn {
  background: none;
  font-size: 12px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f0f0f0;
  min-width: 200px;
  border-radius: 6px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.highlighted-variant {

  background: #ffc950;
  box-shadow: 0px 6.5625px 13.125px rgb(195 195 195 / 33%);
  border: 1px solid #ffffff;
  border-radius: 15px;
  padding: 1px 10px;

}

.report-table td {
  padding: 16px;
}

.summary-report-table {
  background-color: rgb(245, 250, 252);
  font-size: .80rem;
}

.detailed-report-table {
  background-color: rgb(238, 248, 255);
  font-size: .80rem;
}