
/* .health-main {
  width: 100%;
  position: relative;
  z-index: 1;
  height: calc(100vh - 120px);
  background-repeat: no-repeat;
  background: linear-gradient(
    110.27deg,
    rgba(6, 55, 123, 0.8) 4.2%,a
    rgba(16, 107, 147, 0.8) 27.5%,
    rgba(7, 138, 110, 0.8) 69.73%,
    rgba(55, 190, 128, 0.8) 97.39%
  );
  color: white;
}

.health-main::after {
  content: '';
  background-image: url('https://github.com/nehasaipangallu/strand2/blob/main/public/20043%201.svg?raw=true');
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  z-index: 0;
} */


.img-box {
  display: flex;
  z-index: 1;
  height: 226px;
  width: 100%;
  background: linear-gradient(
    rgb(82 131 199 / 80%) 4.2%, rgba(16, 107, 147, 0.8) 27.5%, rgba(7, 138, 110, 0.8) 69.73%, rgba(55, 190, 128, 0.8) 97.39%
  );
  font-size: 10px;
  padding-top: 20px;
  border-radius: 6px;
}

.img-captions {
  width: 100%;
}

.img-captions p {
  color: white;
}


.downloadReport {
  background: #f99d1b;
  box-shadow: 0px 6.5625px 13.125px rgb(0 0 0 / 33%);
  border: 1px solid #d45e1b;
  border-radius: 4px; 
  height: 35px;
  color: white;
  font-size: 14px;
  font-weight: 400;
}

/* .body-para {
  font-size: 20px;
} */
/* .carousel-health {
  height: 450px;
  width: 100%;
  right: 40px;
} */

/* .carousel-health > .carousel,
.carousel-health > .carousel > .carousel-inner {
  height: 100%;
}

.carousel-health > .carousel > .carousel-inner > .carousel-item {
  height: 90%;
} */
/* .flex-car {
  background-color: white;
  color: black;
  z-index: 1;
  height: 100%;
} */

/* .car-img-item {
  width: 500px;
  background-color: white;
  z-index: 1;
} */

/* .carousel-cap {
  color: black;
  left: 10px;
  font-size: 15px;
  position: static;
} */

.bar-box {
  height: 414px;
  width: 530px;
  background-color: white;
  border-radius: 30px;
  align-items: center;
  display: flex;
  margin: auto;
  
}

.bar-img {
  width: 367px;
  align-items: center;
  display: flex;
  margin: auto;
  height: auto;
}
.cancer-bread{
  display: flex;
  justify-content: space-between;
  align-items: center;

} 

@media (max-width: 565px){
  .cancer-bread{
    display:flex;
    /*  */
  
    flex-direction: column;
  }
  .btn2{
    background: #f99d1b;
    border: 1px solid #f99d1b;
    
    border-radius: 30px;
    box-shadow: 0px 4px 7px 2px #ffdd9e69;
    color: #fff;
    
   

  }
  .btncol{
    width: auto;
    padding-bottom: 10px;
  }
  .bread-wid{
    width: auto;
  }
 }

.bar-box-2 {
  height: 411px;
  /* width: 519px; */
  background-color: #33578b;
  border-radius: 30px;
  margin-left: 80px;
  color: #fff;
}
.bar-box-2 h2{
  margin-top: 40px;
}
.bar-box-2 h2{
text-align: center;
}
 




.table-cont {
  color: rgb(32, 32, 32);
  font-size: 14px;
  text-decoration: none;
}

/* .carousel-2-flex > .carousel-2-img {
  width: 500px;
  height: 500px;
} */

/* .carousel-2 {
  background-color: #fff;
  color: black;
} */

/* .body-para > .section1 > .section-head{
  color:#fff
} */

.genes-head{
  color: #fff;
    text-align: center;
    margin-top: 72px;
    margin-bottom: 38px;
    font-weight: 800;
    font-size: 35px;
}

.other-head{
  color: #fff;
  justify-self: center;
}
.other-para{
  color: #fff;
}
.unsorted-list{
  color: #fff;
  font-size: 14px;
}
  /* .carousel-3-img{
    width: 500px;
    height: 500px;
  }
  .carousel-control-prev-icon{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;

  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
  } */

  /* .bg-1-img{
    background-image: url("/public/1.png");
    background-repeat: no-repeat!important;
    
    border-radius: 100px!important;
  }

  .bg-2-img{
    background-image: url("/public/2.png");
    background-repeat: no-repeat!important;
    
    border-radius: 100px!important;
  }
  .bg-2-img{
    background-image: url("/public/3.png");
    background-repeat: no-repeat!important;
    
    border-radius: 100px!important;
  } */

  .carousel-control-next{
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1!important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2% !important;
    padding: 0;
    color:black!important;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
    /* margin-right: -24px!important; */
  
   
    font-size: 55px!important;
   
   
    /* font-size: smaller; */
}

.carousel-control-prev{
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2% !important;
  padding: 0;
  color:black!important;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease;
  /* margin-left: -24px !important; */
 
 
  
  /* font-size: smaller; */
}


.carousel-control-prev-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;

}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.img-1{
  width: 250px;
}

.car-bg{
  box-shadow: 1px 2px 13px 1px #44444430;
border-radius: 20px;
background-color: white!important;
padding: 25px 40px;
}

.bg-1-img{
  border-right: 1px solid black;
}

/* .table>:not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-right: 1px solid #24232345;
  border-top: 1px solid #24232345;
  
  border-left: 1px solid #24232345;
  border-bottom: 1px solid #24232345; 
}*/

.s-1{
  color: #F99D1B;
}

.other-mut{
  font-size: 16px;
}

.other-paragraph{
  text-align: center;
  margin-bottom: 38px;
}

.other-mut-2{
  font-size: 16px;
}

.fle-box1{
  height: auto;
  background-color: #fff;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-radius: 30px;

}



.flex-row1{
  margin: auto;
  align-items: center;
}

.img-2{
  /* width: 250px; */
  margin-left: 92px;
}

.img-fluid-dn{
  width:250px;
  margin-left: 90px;
  margin-bottom: 50px;
}

.carousel-item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 700px; /* Adjust the height as needed */
}

/* .img123{
  height: 412px;
} */
