.status-yes {
  color: #e04b45;
  font-weight: bold;
}

.status-no {
  color: #4f9568;
  font-weight: bold;
}

.status-not-confirmed {
  color: #314b8d;
  font-weight: bold;
}

.relevant-no {
  color: #818282;
  font-weight: bold;
  font-style: italic;
}