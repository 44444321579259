@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,600&display=swap');

.login-page {
  width: 100%;
  position: relative;
  z-index: 1;
  height: calc(100vh - 120px);
  background: linear-gradient(
    110.27deg,
    rgba(6, 55, 123, 0.8) 4.2%,
    rgba(16, 107, 147, 0.8) 27.5%,
    rgba(7, 138, 110, 0.8) 69.73%,
    rgba(55, 190, 128, 0.8) 97.39%
  ) no-repeat;
  color: white;
}

.heading-log {
  color: #ffffff;
}

.login-page * {
  z-index: 1;
}

.login-page::after {
  content: '';
  background-image: url('https://github.com/nehasaipangallu/strand2/blob/main/public/20043%201.svg?raw=true');
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  z-index: 0;
}

.glassBGContainer {
  padding: 30px;
  width: 450px
}

.glassBG,
.otp-background {
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.1);
  border: 3px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.11);
  /* backdrop-filter: blur(4px); */
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px;
}

/* .glassBG > *,
.otp-background > * {
  margin: 20px;
} */
.mobile-input-container {
  border: 1px solid #000000;
  height: 50px;
}

.country-code {
  display: inline-block;
  width: 15%;
  font-size: 20px;
  color: #cbcbcb;
}

.mobile-input {
  background: #ffffff;
  border: none;
  height: 40px;
  outline: none;
  font-size: 20px;
}

.mobile-input:focus-visible,
.mobile-input:active,
.mobile-input:focus,
.mobile-input:hover {
  border: none;
}

.send-otp,
.verify-otp {
  background: rgb(254, 174, 44);
  background: linear-gradient(90deg, rgba(254, 174, 44, 1) 0%, rgba(255, 192, 28, 1) 35%, rgb(233, 204, 41) 100%);
  box-shadow: 0px 3px 12px 5px rgb(31 118 78 / 33%);
  border: 1px solid #a7880056;
  border-radius: 5px;
  height: 50px;
  color: white;
  width: 250px
}


.send-otp:hover,
.verify-otp:hover {
  background: rgb(254, 174, 44);
  background: linear-gradient(90deg, rgba(254, 174, 44, 1) 0%, rgba(255, 192, 28, 1) 35%, rgb(233, 204, 41) 100%);
  box-shadow: 0px 3px 12px 5px rgb(31 118 78 / 33%);
  border: 1px solid #d45e1b;
  border-radius: 5px;
  height: 50px;
  color: white;
}

.otp-box-container>input {
  flex: 1;
  margin: 8px;
  text-align: center;
}

.otp-input {
  background: #ffffff;
  border: 1px solid #ffffff;
  width: 80%;
  height: 45px;
}

.heading-log {
  font-weight: 400;
  font-size: 28px;
}

.otp-heading {
  font-weight: 400;
  font-size: 32px;
}

.heading-log-2 {
  font-size: 22px;
}

.btn {}

.resend-otp-btn {
  color: #06377b;
  background: no-repeat;
  border: none;
  font-size: 12px;
}

.countdown-text {
  font-size: 12px;
}

.otp-alert {
  border: 1px solid #fb848e;
  background-color: #f8d7da;
  color: #842029;
  font-size: 16px;
}

.alert-box {
  border: 1px solid #fb848e;
  background-color: #f8d7da;
  color: #842029;
  font-size: 12px;
  height: 50px;
  margin-top: 20px;
}

.country-name {
  color: #000000;
}

/* responsive */
@media screen and (max-width: 480px) {
  .glassBGContainer {
    width: 100% !important;
    padding: 15px 10px;
  }

  .glassBG,
  .otp-background {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    border: 3px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.11);
    /* backdrop-filter: blur(4px); */
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 8px;
  }

  .glassBG>*,
  .otp-background>* {
    padding: 8px 10px;
  }

  .mobile-input-container {
    width: 100%;
    border: 1px solid #000000;
    height: 50px;
  }

  .heading-log {
    font-size: 20px;
  }

  .country-code {
    display: inline-block;
    width: 15%;
    font-size: 20px;
    color: #cbcbcb;
  }

  .mobile-input {
    background: #ffffff;
    border: none;
    width: 85%;
    height: 40px;
    outline: none;
    font-size: 20px;
  }

  .mobile-input:focus-visible,
  .mobile-input:active,
  .mobile-input:focus,
  .mobile-input:hover {
    border: none;
  }

  .send-otp,
  .verify-otp {
    background: #f99d1b;
    border: 1px solid #d45e1b;
    box-shadow: 0px 0px 14px 2px rgba(1, 67, 16, 0.29);
    border-radius: 5px;
    width: 80%;
    height: 50px;
    color: white;
  }

  .otp-box-container>input {
    flex: 1;
    margin: 8px;
    text-align: center;
  }

  .otp-input {
    background: #ffffff;
    border: 1px solid #ffffff;
    width: 80%;
    height: 45px;
  }

  .heading-log {
    font-weight: 400;
  }

  .heading-log-2 {
    font-size: 22px;
  }

  .btn {}

  .resend-otp-btn {
    color: #06377b;
    background: no-repeat;
    border: none;
    font-size: 12px;
  }

  .countdown-text {
    font-size: 12px;
  }

  .otp-alert {
    border: 1px solid #fb848e;
    background-color: #f8d7da;
    color: #842029;
    font-size: 16px;
  }
}