@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,600&display=swap');

.pageBg {
  width: 100%;
  position: relative;
  z-index: 1;
  height: calc(100vh - 120px);
  background: linear-gradient(
    110.27deg,
    rgba(6, 55, 123, 0.8) 4.2%,
    rgba(16, 107, 147, 0.8) 27.5%,
    rgba(7, 138, 110, 0.8) 69.73%,
    rgba(55, 190, 128, 0.8) 97.39%
  ) no-repeat;
  color: white;
}

.pageBanner{
  background-image: url('../../../public/assets/BannerBG.jpg');
  background-size: cover;
  min-height: calc(100vh - 120px);
  display: flex;
    justify-content: center;
    align-items: center;
}

.bannerLeftImage{
  height:450px;
  opacity: 0.9;
  width: auto;
}

.home-page * {
  z-index: 1;
}


.home-page-container {
  width: 100%;
  padding: 0 0%;
}
.para {
  font-size: 16px;
}
.btn1 {
  background: #f99d1b;
  background: rgb(254,174,44);
  background: linear-gradient(90deg, rgba(254,174,44,1) 0%, rgba(255,192,28,1) 35%, rgb(233, 204, 41) 100%);
  box-shadow: 0px 3px 12px 5px rgb(31 118 78 / 33%);
  border-radius: 6px;
  width: 220px;
  height: 55px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.para-heading {
color: #fff !important;
  font-size: 32px;
  font-weight: 400 !important;
}


@media only screen and (max-width: 600px) {
  .bannerLeftImage{
    height:auto;
    opacity: 0.9;
  }
}
