@import url('https://fonts.googleapis.com/css2?family=Baloo+2&family=Lobster&family=Open+Sans:ital,wght@0,600;1,600&display=swap');


.header {
  width: 100% !important;
  height: 90px;
  color: rgb(12, 12, 12);
  background-color: white !important;
}

.nav-items a {
  justify-content: space-evenly;
  display: block !important;
  margin: 0px;
  font-size: 16px;
  font-weight: 600; 
  
  padding: 10px 16px;
  line-height: 20px;
}

.menuHighlight{
  border: 1px solid #444;
    border-radius: 6px;
}

.menuHighlight2{
  border: 1px solid #e14d45;
  background-color: #e14d45;
  border-radius: 6px;
  color: #fff;
}

.menuHighlight2:hover{
  border: 1px solid #e14d45;
  background-color: #e14d45;
  border-radius: 6px;
  color: #fff;
}

.nav-brand-img {
  margin-left: 0rem;
}
.nav-items {
  justify-content: flex-end;
}

.logo {
  height: 60px;
  width: auto;
}


.header .heading {
  font-weight: 600;
}



.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  background-color: #fff;
  /* padding: 20px;
    margin-top: 13px; */
}

.nav-items svg{
  margin-top: -5px;
    color: #404550;
}

/* responsive */
@media screen and (max-width: 480px) {
  .header {
    width: 100% !important;
    height: 90px;
    color: rgb(12, 12, 12);
    background-color: white !important;
  }

  .nav-items a {
    justify-content: space-evenly;
    display: block !important;
    margin: 0px;
    font-size: 14px;
    font-weight: 600;        
    padding: 11px 10px;
  }
}

@media screen and (max-width: 700px) {
  .nav-items a {
    justify-content: space-evenly;
    display: block !important;
    margin: 0px;
    font-size: 14px;
    font-weight: 600;     
    padding: 11px 10px !important;
  }

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    background-color: #fff;
    padding: 20px;
      margin-top: 13px;
  }
}

@media screen and (max-width: 992px) {
 
}
@media screen and (max-width: 600px) {
  
}

@media screen and (min-device-width: 575px) and (max-device-width: 992px) {
 
}

@media screen and (min-device-width: 574px) and (max-device-width: 584px) {
  .nav-items a {
    justify-content: space-evenly;
    display: block !important;
    margin: 0px;
    font-size: 11px;
    font-weight: 600;
    float: right !important;
    text-align: center;
    padding: 6px 6px !important;
  }
}

@media  screen and (max-device-width:1236px) {
  .head-txt{
    font-size: 14px !important;
  }
}

@media screen and (max-device-width:1170px) {
.head-txt{
  font-size: 9px !important;
}
  
}

/* @media screen and (max-device-width:1106px) {
  .head-txt{
    font-size: 10px !important;
  }
} */

@media  screen and(max-device-width:1046px) {
  .head-txt{
    font-size: 9px !important;
  }
}

@media screen and(max-width:1215px){
  .head-txt{
    font-size: 9px !important;
  }
}

@media screen and(max-width:1095px) {
  .head-txt{
    font-size: 7px !important;
  }
  
}

